<template>
  <markets-summary />
</template>

<script>
import { defineComponent } from "vue";
import MarketsSummary from "@/components/summaries/MarketsSummary.vue";
export default defineComponent({
  name: "Markets",
  components: {
    MarketsSummary,
  },
  setup() {},
});
</script>

<style>
</style>
