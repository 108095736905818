<template>
  <markets-summary-skeleton v-if="loading" />
  <error v-else-if="error" />
  <DataTable
    :ref="table"
    :columns="columns"
    :options="options"
    :ajax="{url: apiUrl + 'markets'}"
    class="table table-hover table-responsive markets-table rounded"
  />
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { formatDateElapsedTime, formatDate } from "@/utils/date.js";
import Error from "@/components/Error.vue";
import MarketsSummarySkeleton from "@/components/skeletons/summaries/MarketsSummarySkeleton.vue";
import DataTable from "datatables.net-vue3";
import "datatables.net-responsive";
import DataTablesCore from "datatables.net-bs5";
import { removeTrailingZeros, formatNumberLocale } from "@/utils/string";
import $ from 'jquery'

DataTable.use(DataTablesCore);

export default defineComponent({
  name: "MarketsSummary",
  components: {
    Error,
    MarketsSummarySkeleton,
    DataTable,
  },
  setup() {
    const store = useStore();
    const table = ref();
    const loading = computed(() => store.getters["ledgers/getLoadingStatus"]);
    const error = computed(() => store.getters["ledgers/getError"]);

    const goToAsset = (assetCode) => {
      this.$router.push({ name: "asset", params: { assetCode: assetCode } });
    };

    const apiUrl = process.env.VUE_APP_API_URL;

    const ellipsisWord = (word) => {
        if (word.length > 35) {
            return word.substr(0, 5) + '...' + word.substr(word.length-5, word.length);
        }
        return word;
    }

    const xlmValue = 0.1189;

    const columns = computed(() => {
      return [
        {
          data: "rating",
          title: "Rating",
          searchable: false,
          visible: false,
          render: (data) => {
            return data;    
          }
        },
        {
          data: "asset_code",
          title: "Name",
          render: (data, _type, row) => {
            let logoUrl = row.logo
              ? row.logo
              : require("@/assets/images/stellar-logo.png");
            return (
            `<div class="d-flex gap-2 align-items-center">`+
                `<span class="bg-body-tertiary p-1 rounded"><img style="width: 40px;height:auto" src="${logoUrl}" /> </span>` +
                `<span>` + 
                    `<a class="text-decoration-none text-reset" href="/assets/${row.asset_code}-${row.asset_issuer}"><div class="fw-bold text-decoration-none text-reset">${data}</div></a>` +
                    `<div class="badge text-secondary bg-body-secondary small"> ${row.domain} </div> ` +
                    `<div class="badge text-secondary bg-body-secondary small"> ${ellipsisWord(row.asset_issuer)} </div>` +
                `</span>` +
            `</div>`
            );
          },
        },
        {
          data: "1d_close_price",
          title: "Price",
          searchable: false,
          className: '',
          render: (data) => {
            return (
            `<div>$${data*xlmValue}` +
            `</div>`+
            `<div class="small text-secondary">${parseFloat(data).toFixed(8).toLocaleString()}` +
            ` <small>XLM</small>` +
            `</div>`
            );
          },
        },
        {
          data: "1d_change",
          title: "24h %",
          className: '',
          searchable: false,
          render: (data) => {
            let color = data > 0 ? "text-success" : "text-danger";
            return '<span class="'+color+'"> ' + parseFloat(data).toFixed(2) + "% </span>";
          },
        },
        {
          data: "7d_change",
          title: "7d %",
          className: '',
          searchable: false,
          render: (data) => {
            let color = data > 0 ? "text-success" : "text-danger";
            return '<span class="'+color+'"> ' + parseFloat(data).toFixed(2) + "% </span>";
          },
        },
        {
          data: "market_cap",
          title: "Market Cap",
          className: '',
          render: (data) => {
            return '$' + formatNumberLocale(data);
          },
        },
        {
          data: "7d_volume",
          title: "Volume (7d)",
          className: '',
          searchable: false,
          render: (data, _type, row) => {
            if (!data){
                return 0
            }
            return (
            `<div>${(data > 0.01 ? parseFloat(data).toLocaleString() : removeTrailingZeros(data))} XLM` +
            `</div>`+
            `<div class="small text-secondary">${(row['1d_volume'] > 0.01 ? parseFloat(row['1d_volume']).toLocaleString() : removeTrailingZeros(row['1d_volume']))}` +
            ` <small>XLM (24h)</small>` +
            `</div>`
            );
          },
        },
        {
          data: "1d_total_trades",
          title: "Trades(24h)",
          className: '',
          searchable: false,
          render: (data) => {
            return data && data.toLocaleString()
          },
        },
        {
          data: "num_accounts",
          title: "Trustlines",
          className: '',
          searchable: false,
          render: (data) => {
            return data.toLocaleString()
          },
        },
      ];
    });

    const options = computed(() => {
      return {
        responsive: true,
        select: true,
        serverSide: true,
        processing: true,
        order: [0, "desc"],
        pageLength: 25,
        createdRow: function (row, data, dataIndex) {
            console.log(data)
            $(row).addClass("bg-body");
        },
        fixedHeader: true,
        pagingType: 'full_numbers',
        dom: "<'row'<'col-sm-12 col-md-12 align-items-center d-flex'f>>" +
            "<'row mt-2'<'col-sm-12'tr>>" +
            "<'container-fluid bg-body sticky-bottom border py-1 px-1 rounded'<'row'<'col-xs-12 col-md-7 d-flex text-muted d-none d-sm-block'i><'col-xs-12 col-md-5 justify-content-end d-flex text-muted'pl>>>",
        language: {
            searchPlaceholder: "Search asset in market",
            search: "",
            lengthMenu: "_MENU_",
            paginate: {
                previous: "<i class='bi bi-chevron-left small'></i>",
                next: "<i class='bi bi-chevron-right small'></i>",
                last: "<i class='bi bi-chevron-bar-right'></i>",
                first: "<i class='bi bi-chevron-bar-left'></i>"
            }
        }
      };
    });

    return {
      store,
      formatDateElapsedTime,
      formatDate,
      loading,
      error,
      columns,
      options,
      goToAsset,
      table,
      apiUrl,
    };
  },
});
</script>
